import { Box, Button } from "@chakra-ui/react"
import ArrowIcon from "@components/Icons/Arrow"

const svg = {
  width: "20px",
  height: "20px",
}

const CarouselControls = ({ id }: { id: string }) => {
  return (
    <Box display={["none", null, null, "flex"]}>
      <Button
        className={`swiper-prev-${id}`}
        size="sm"
        mr="2"
        variant="outline"
        color="orange"
        borderColor="orange"
      >
        <ArrowIcon
          sx={{
            transform: `rotate(180deg)`,
            svg,
          }}
        />
      </Button>
      <Button
        className={`swiper-next-${id}`}
        size="sm"
        variant="outline"
        color="orange"
        borderColor="orange"
      >
        <ArrowIcon sx={{ svg }} />
      </Button>
    </Box>
  )
}

export default CarouselControls
