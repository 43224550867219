import Image from "next/image"
import { Box, Heading } from "@chakra-ui/react"
import { Step } from "@typings/index"
import RichText from "@components/Show/RichText"
import { imageLoader } from "@utils/imageLoader"

const StepItem = ({ step, i }: { step: Step; i: number }) => (
  <>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="12"
      height="12"
      mx="auto"
      mb="4"
      borderRadius="50"
      backgroundColor="orange"
      color="white"
    >
      <Heading variant="contentHeading2">{i + 1}</Heading>
    </Box>
    {step.image && (
      <Box
        position="relative"
        border="2px solid"
        borderColor="orange"
        display="block"
        overflow="hidden"
        borderRadius="lg"
        cursor="pointer"
      >
        <Image
          loader={imageLoader}
          src={step.image?.url}
          layout="responsive"
          objectFit="cover"
          width={step.image?.width ?? 0}
          height={step.image?.height ?? 0}
          alt={""}
        />
      </Box>
    )}
    {step.text && (
      <Box mt="4" textAlign="center" cursor="pointer">
        {<RichText doc={step.text} />}
      </Box>
    )}
  </>
)

export default StepItem
