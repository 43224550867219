import { Box } from "@chakra-ui/layout"
import { SystemStyleObject } from "@chakra-ui/styled-system"

const LockIcon = ({ sx }: { sx?: SystemStyleObject }): JSX.Element => (
  <Box sx={sx}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-lock"
    >
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
      <path d="M7 11V7a5 5 0 0110 0v4" />
    </svg>
  </Box>
)

export default LockIcon
