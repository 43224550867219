import Image from "next/image"
import Link from "next/link"
import { Box, Heading } from "@chakra-ui/layout"
import { Show, Version } from "@typings/index"
import { useTranslations } from "use-intl"
import PurchaseTicket from "@components/PurchaseTicket"
import AccessBadge from "@components/Show/AccessBadge"
import ShowStatus from "@components/Show/ShowStatus"
import TicketBadge from "@components/TicketBadge"
import { getEventStatus } from "@utils/helpers"
import { imageLoader } from "@utils/imageLoader"

const ShowCard = ({ show }: { show: Show }): JSX.Element => {
  const {
    uuid,
    coverImage,
    coverImageThumbnail,
    slug,
    title,
    version,
    showStartTime,
    showEndTime,
    price,
  } = show
  const t = useTranslations("common")
  const status = getEventStatus(showStartTime, showEndTime)

  const hasAccess = version === Version.paid

  return (
    <Link href={`/${t("show")}/${slug}`} passHref>
      <Box
        as="a"
        display="block"
        position="relative"
        paddingTop="100%"
        backgroundColor="#222"
        borderRadius="lg"
        overflow="hidden"
        cursor="pointer"
        sx={{
          h2: {
            transition: "transform 0.3s ease-out",
          },
          img: {
            transition: "transform 0.35s ease-out",
          },
          _hover: {
            h2: {
              transform: `translateY(-5px)`,
            },
            img: {
              transition: "transform 0.35s ease-out",
              transform: `scale(1.05)`,
            },
          },
        }}
      >
        <Image
          loader={imageLoader}
          src={coverImageThumbnail?.url || coverImage.url}
          objectFit="cover"
          layout="fill"
          alt={title}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bgGradient="linear(to-b, rgba(30, 30, 35, 0), rgba(30, 30, 35, 0.6))"
        />
        <AccessBadge
          hasAccess={hasAccess}
          sx={{
            position: "absolute",
            top: [4, null, 6],
            left: [4, null, 6],
            color: "white",
          }}
        />
        <Box
          position="absolute"
          left="4"
          bottom="4"
          width={[`calc(100% - 2rem)`]}
        >
          <Heading variant="contentHeading2" color="white">
            {title}
          </Heading>
          <ShowStatus
            showStartTime={showStartTime}
            showEndTime={showEndTime}
            mt={1}
            showLabel={false}
            sx={{ color: "white" }}
          />
          {hasAccess ? (
            <TicketBadge
              sx={{
                mt: [2, null, 3],
                backgroundColor: "gray.100",
                borderColor: "rgba(255,255,255)",
              }}
            />
          ) : (
            <PurchaseTicket
              hasAccess={hasAccess}
              showId={uuid}
              mt={[3, 4]}
              showAdditionalInfo={false}
              price={price}
              status={status}
            />
          )}
        </Box>
      </Box>
    </Link>
  )
}

export default ShowCard
