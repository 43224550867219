import { MouseEvent } from "react"
import NextLink from "next/link"
import { useRouter } from "next/router"
import {
  Box,
  BoxProps,
  Link,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  ModalBody,
  Text,
  SystemStyleObject,
} from "@chakra-ui/react"
import { isNil } from "ramda"
import { eventStatus } from "typings"
import { useTranslations } from "use-intl"
import { useUser } from "@contexts/userContext"
import { getDisplayPrice, getPriceData } from "@utils/pricing"

interface PurchaseProps extends BoxProps {
  showId: string
  hasAccess: boolean
  showAdditionalInfo?: boolean
  sx?: SystemStyleObject
  price: string
  status: eventStatus
}

const PurchaseTicket = (props: PurchaseProps): JSX.Element => {
  const {
    hasAccess,
    showId,
    price,
    showAdditionalInfo = true,
    status,
    sx,
    ...rest
  } = props
  const { user } = useUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const router = useRouter()
  const { locale, defaultLocale = "fi" } = router
  const t = useTranslations()
  const { price: priceInCents, currencyCode } = getPriceData(price)

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (isNil(user)) {
      onOpen()
      return
    }

    router.push(`/${t("common.order")}/${showId}`)
  }

  if (hasAccess) {
    return <></>
  }

  return (
    <>
      <Box
        sx={{
          "@media print": {
            display: "none",
          },
          ...sx,
        }}
        {...rest}
      >
        <Button
          // href={user ? `/${t("purchaseTicket.order")}/${showId}` : `#`}
          fontSize={"sm"}
          onClick={handleButtonClick}
        >
          {t(
            status === eventStatus.past
              ? "purchaseTicket.buyViewingRight"
              : "purchaseTicket.buyTicket"
          )}{" "}
          {getDisplayPrice(locale || defaultLocale, currencyCode, priceInCents)}
        </Button>
        {showAdditionalInfo && (
          <Box mt="3">
            <NextLink href="/faq" passHref>
              <Link>{t("purchaseTicket.moreInformation")}</Link>
            </NextLink>
          </Box>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay background="blackAlpha.800" />
        <ModalContent background="transparent">
          <ModalHeader>
            {t("purchaseTicket.anonymousUserModalTitle")}
          </ModalHeader>
          <ModalCloseButton
            _hover={{ path: { fill: "orange" } }}
            _focus={{ outline: "orange" }}
          />
          <ModalBody>
            <Text>
              {t("purchaseTicket.anonymousUserModalBody")}{" "}
              <NextLink href={String(t("navigation.signUpUrl"))} passHref>
                <Link>{t("purchaseTicket.signUpLabel")}</Link>
              </NextLink>
            </Text>
            <br />
            <Text>
              {t("purchaseTicket.signInTitle")}{" "}
              <NextLink href={String(t("navigation.signInUrl"))} passHref>
                <Link>{t("navigation.signInLabel")}</Link>
              </NextLink>
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PurchaseTicket
