import { Tooltip, Flex, SystemStyleObject } from "@chakra-ui/react"
import { useTranslations } from "use-intl"
import LockIcon from "../Icons/Lock"

type LabelProps = {
  hasAccess: boolean
  sx?: SystemStyleObject
}

const AccessBadge = (props: LabelProps): JSX.Element => {
  const { hasAccess, sx } = props
  const t = useTranslations("show")

  if (hasAccess) return <></>

  return (
    <Tooltip
      label={String(t("accessBadge.toolTipMsg"))}
      aria-label="A tooltip"
      bg="orange"
      hasArrow
      borderRadius="lg"
    >
      <Flex
        alignItems="center"
        justifyContent="space-around"
        w="36px"
        h="36px"
        borderRadius="full"
        backgroundColor="black"
        sx={sx}
      >
        <LockIcon />
      </Flex>
    </Tooltip>
  )
}

export default AccessBadge
