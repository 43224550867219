import { Box } from "@chakra-ui/layout"
import { SystemStyleObject } from "@chakra-ui/styled-system"

const ArrowIcon = ({ sx }: { sx?: SystemStyleObject }): JSX.Element => (
  <Box sx={sx}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14M12 5l7 7-7 7" />
    </svg>
  </Box>
)

export default ArrowIcon
