import { Swiper, SwiperSlide } from "swiper/react"
import { useEffect, useState } from "react"
import { Box, useBreakpointValue } from "@chakra-ui/react"
import { Show, Step } from "@typings/index"
import { Navigation } from "swiper"
import { isShow, isStep } from "@utils/helpers"
import ShowCard from "./ShowCard"
import StepItem from "./StepItem"

type CarouselProps = {
  id: string
  items: Show[] | Step[]
}

const ItemPicker = ({
  item,
  i,
}: {
  item: Step | Show
  i: number
}): JSX.Element => {
  if (isStep(item)) {
    return <StepItem step={item as Step} i={i} />
  }

  if (isShow(item)) {
    return <ShowCard show={item as Show} />
  }
  return <></>
}

const Carousel = ({ id, items }: CarouselProps): JSX.Element => {
  const sliderStyles = {
    marginLeft: "-1rem",
    marginRight: "-1rem",
    paddingLeft: "1rem",
  }

  const [slidePadding, setSlidePadding] = useState("0.8rem")

  const slidesPerView = useBreakpointValue({
    base: 1.5,
    md: 2.5,
    lg: 2.8,
    xl: 3.2,
  })

  const slidePaddingBP = useBreakpointValue({
    md: "1.2rem",
    lg: "1.5rem",
    xl: "1.8rem",
  })

  useEffect(() => {
    if (typeof slidePaddingBP === "string") {
      setSlidePadding(slidePaddingBP)
    }
  }, [slidePaddingBP])

  return (
    <Box width="100%" textAlign="left" mt={[2, 4]}>
      <Swiper
        modules={[Navigation]}
        slidesPerView={slidesPerView}
        loop={false}
        style={sliderStyles}
        navigation={{
          nextEl: `.swiper-next-${id}`,
          prevEl: `.swiper-prev-${id}`,
        }}
      >
        {items.map((item, i) => (
          <SwiperSlide
            key={id + i}
            style={{ paddingRight: slidePadding || "0.8rem" }}
          >
            <ItemPicker item={item} i={i} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default Carousel
